import React, { useState } from "react";
import {
  CircularProgress,
  Typography,
  Button,
  Fade,
  InputAdornment,
  IconButton,
  Link,
} from "@material-ui/core";
import { TextField } from "material-ui-formik-components";
import useStyles from "./styles";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../reducers";
import { Formik, Field } from "formik";
import { AuthInfo, registerValidation } from "./index";
import { UserDispatch } from "../../model";
import { registerUser } from "../../actions/user";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Form, Checkbox } from "formik-antd";
import ReCAPTCHA from "react-google-recaptcha";
import { Select } from "formik-antd";
import { sectors } from "../../pages/create/components/data";
import { useHistory } from "react-router-dom";
import { callableFunctions } from "../../repositories/firebase";

interface Props {
  plan: string | null;
  from: string;
}

const { Option } = Select;

export function RegisterForm(props: Props) {
  // global
  const user = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch<UserDispatch>();
  const history = useHistory();
  const classes = useStyles();
  const [passwordShown, setPasswordShown] = useState<boolean>(false);
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleSubmit = async (values: AuthInfo) => {
    if (!recaptchaToken) {
      console.log('reCAPTCHA token is missing');
      return;
    }

    setIsSubmitting(true);

    try {
      const verifyRecaptcha = callableFunctions.httpsCallable('verifyRecaptcha');
      const result = await verifyRecaptcha({ token: recaptchaToken });

      if (result.data.success) {
        await dispatch(
          registerUser(values.name, values.lastName, values.email, values.sector, values.password, props.plan, history, props.from)
        );
      } else {
        console.log('reCAPTCHA verification failed:', result.data.error);
      }
    } catch (error) {
      console.error('reCAPTCHA verification error:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Formik<AuthInfo>
      validateOnMount
      validateOnChange
      validateOnBlur
      initialValues={{
        name: "",
        lastName: "",
        email: "",
        password: "",
        sector: "",
        terms: false,
      }}
      validationSchema={registerValidation}
      onSubmit={handleSubmit}
    >
      {(formik) => {
        const isFormValid = formik.isValid && recaptchaToken && !isSubmitting;
        return (
          <Form>
            <Typography variant="h4" className={classes.greeting}>
              Welcome!
            </Typography>
            <Typography variant="h5" className={classes.subGreeting}>
              Create your account
            </Typography>
            <Field
              className={classes.formikPassword}
              required
              name="name"
              label="First Name"
              component={TextField}
              margin="normal"
              placeholder="Full Name"
              type="text"
              fullWidth
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
            />

            <Field
              className={classes.formikPassword}
              required
              name="lastName"
              label="Last Name"
              component={TextField}
              margin="normal"
              placeholder="Last Name"
              type="text"
              fullWidth
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
            />

            <Select
              showSearch
              name="sector"
              placeholder="Select a sector"
              optionFilterProp="children"
              style={{ width: "100%", marginTop: 16, marginBottom: 8 }}
              className={
                classes.textFieldUnderline +
                " " +
                classes.selectField +
                " " +
                classes.selectorTextField
              }
              filterOption={(input, option) => {
                if (option)
                  return (
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  );
                return true;
              }}
              onDropdownVisibleChange={(open) => {
                if (!open && document.activeElement instanceof HTMLElement)
                  document.activeElement.blur();
              }}
            >
              {sectors.map((sector) => (
                <Option value={sector.value} key={sector.value}>
                  {sector.label}
                </Option>
              ))}
            </Select>

            <Field
              className={classes.formikPassword}
              required
              name="email"
              label="Email"
              component={TextField}
              margin="normal"
              placeholder="Email Address"
              type="email"
              fullWidth
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
            />
            <Field
              className={classes.formikPassword}
              required
              name="password"
              label="Password"
              component={TextField}
              margin="normal"
              placeholder="Password"
              type={passwordShown ? "text" : "password"}
              fullWidth
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={(e) => {
                        e.preventDefault();
                        setPasswordShown(!passwordShown);
                      }}
                      edge="end"
                    >
                      {passwordShown ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Checkbox name="terms" style={{ marginTop: 10 }}>
              <Typography variant="caption" className={classes.terms}>
                By clicking the checkbox, you agree to abide by the terms and
                conditions as outlined in{" "}
                <Link href="/terms-and-conditions.pdf">our T&C’s Policy</Link>
              </Typography>
            </Checkbox>
            <ReCAPTCHA
              sitekey="6LcX5B8qAAAAAK0aDQ1hJ7_Vaqmr3U6Ic-9JgC_R"
              onChange={(token) => setRecaptchaToken(token)}
            />
            <Fade in={!!user.error}>
              <Typography color="secondary" className={classes.errorMessage}>
                {user.error ?? "Something went wrong during login."}
              </Typography>
            </Fade>
            <div className={classes.creatingButtonContainer}>
              {isSubmitting ? (
                <CircularProgress size={26} />
              ) : (
                <Button
                  type="submit"
                  disabled={!isFormValid}
                  size="large"
                  variant="contained"
                  color="primary"
                  fullWidth
                  className={classes.createAccountButton}
                >
                  Create your account
                </Button>
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
