import React, { useEffect } from "react";
import {
  Route,
  Switch,
  Redirect,
  BrowserRouter,
  useLocation,
} from "react-router-dom";

// components
import Layout from "./Layout/Layout";
// pages
import * as pages from "../pages";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../reducers";
import { isLoaded, isEmpty } from "react-redux-firebase";
import Loader from "./Loader/Loader";
import { UserDispatch } from "../model";
import { redirectToPlan } from "../actions/user";
import { setWalletOpen } from "../actions/metamask";
import PaymentSuccessFull from "../pages/create/components/PaymentSuccessFull/PaymentSuccessFull"
// import Drafts from "../pages/drafts/Drafts";
declare var window: any
const networkChanged = (chainId) => {
  console.log({ chainId });
};
export default function App() {
  const auth = useSelector((state: RootState) => state.firebase.auth);
  const user = useSelector((state: RootState) => state.user);

  // const walletOpen = useSelector((state: RootState) => state.metamask.walletOpen);
  const isAuthenticated = isLoaded(auth) && !isEmpty(auth);
  const dispatch = useDispatch();
  // const [walletOpen,setWalletOpen] = useState(true);
  
  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on('disconnect', (error: ProviderRpcError) => {
        window.location.reload(true);
          dispatch(setWalletOpen(true));
      });
      window.ethereum.on('chainChanged', () => {
        window.location.reload(true);
        return () => {
          dispatch(setWalletOpen(true));
          window.ethereum.removeListener("chainChanged", networkChanged);
        };
      })
      window.ethereum.on('accountsChanged', () => {
        window.location.reload(true);
        dispatch(setWalletOpen(true));
      })
    }
  }, [])

  if (!isLoaded(auth)) return <Loader />;
  if (user.paymentLoading)
    return <Loader text="Redirecting to payment portal..." />;

  return (
    <BrowserRouter>
    {/* <pages.WalletConnect walletOpen={walletOpen} setWalletOpen={setWalletOpen} isAuthenticated={isAuthenticated}/> */}

      <Switch>
        <Route exact path="/" render={() => <Redirect to="/app/dashboard" />} />
        <Route
          exact
          path="/app"
          render={() => <Redirect to="/app/dashboard" />}
        />
        <Route exact path="/subscription" component={Payment} />
        <Route path="/form" component={pages.Form} />
        <PrivateRoute path="/typeform/expertScore" component={pages.ExpertScore} />
        <PrivateRoute path="/typeform/technicalScore" component={pages.TechnicalScore} />
        <PrivateRoute path="/create" component={pages.Create} />
        <PrivateRoute path="/app/create/:id" component={pages.Create} />

        {/* <PrivateRoute path="/plan" component={pages.SubscriptionCard} /> */}
        <PrivateRoute path="/test" component={pages.Test} />
        <PrivateRoute path="/payment-success/:planId" component={PaymentSuccessFull} />
        <PrivateRoute path="/payment-success" component={PaymentSuccessFull} />
        {/* <PrivateRoute path="/app/drafts" component={pages.Drafts} />       */}




        <PrivateRoute path="/createTechnicalScore" component={pages.CreateTechnicalScore} />

        {/* <PrivateRoute path="/download/:id" component={DownloadableReport} /> */}
        <PrivateRoute path="/app" component={Layout} />
        <PublicRoute path="/forgotPassword" component={pages.ForgotPassword} />
        <PublicRoute path="/reset/newpassword" component={pages.ResetForgetPassword} />
        <PublicRoute path="/login" component={pages.Login} />
        <PrivateRoute path="/connect" component={pages.WalletConnect} />
        {/* __________________OVERVIEW___________________ */}
        <PrivateRoute path="/overview/:id" component={pages.OverView} />
          {/* __________________NEW SCORE___________________ */}
          <PrivateRoute path="/newscore/:id/:section" component={pages.NewScore} />
        <Route component={pages.Error} />
      </Switch>
    </BrowserRouter>
  );

  // #######################################################################

  function PrivateRoute({ component, ...rest }: any) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated  ? (
            // React.createElement(component, {...props,walletOpen:walletOpen,'setWalletOpen':setWalletOpen})
            React.createElement(component, {...props})
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }: any) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}

function Payment() {
  const location = useLocation();
  const dispatchPayment = useDispatch<UserDispatch>();
  const profile = useSelector((state: RootState) => state.firebase.profile);
  const searchParams = new URLSearchParams(location.search);
  const plan = searchParams.get("plan");

  useEffect(() => {
    if (plan && !profile.plan) dispatchPayment(redirectToPlan(plan));
  });

  return (
    <Redirect
      to={{
        pathname: `/login?plan=${plan}`,
        state: {
          from: location,
        },
      }}
    />
  );
}
